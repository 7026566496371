import React, { useState, useContext } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { SHA256, enc } from "crypto-js";
import jsonToCsv from "../../services/utils";
import AuthContext from '../../context/AuthContext';

const ReportForm = ({ setData, setSnackOpen, setMessageOpen, setSeverity }) => {
  const { token, acquirerData } = useContext(AuthContext);
  const [currency, setCurrency] = useState(acquirerData?.currency_code || "");
  const [reportDate, setReportDate] = useState("");
  const [hide, setHide] = useState(true);
  const [csvData, setCsvData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const minDate = () => {
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90); // Set the minimum date to 90 days before today
    return ninetyDaysAgo.toISOString().split("T")[0]; // Format as "YYYY-MM-DD" for the input type="date"
  };

  const maxDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1); // Set the maximum date to yesterday
    return today.toISOString().split("T")[0]; // Format as "YYYY-MM-DD" for the input type="date"
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setReportDate(selectedDate);
  };

  const handleDownload = () => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `report_clearing_settlement_${reportDate}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const url =
        process.env.REACT_APP_API_ROUTE + "/daily-clearing-settlement";
      let data = {
        version: "04.08.0X",
        acquirer: {
          callbackURL: "https://daily-clearing&settlement.merchantpartners.com",
          timeStamp: new Date().toISOString(),
        },
        fiat: {
          currencyCode: currency,
        },
        report: {
          date: reportDate.replace(/-/g, ""),
          // date: "20220930",
        },
        reqhashMAC: "",
      };
      const dataString = JSON.stringify(data);
      const hash = SHA256(dataString + token).toString(enc.Base64);
      data.reqhashMAC = hash;

      const response = await axios.post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          acquirerName: acquirerData.acquirerName,
          acquirerID: acquirerData.acquirerId,
        },
      });
      const dataFormat = jsonToCsv(response.data);
      setCsvData(dataFormat);
      setData(response.data);
      setHide(response.data?.holdings?.holding.length > 0 ? false : true);
      setSnackOpen(response.data?.holdings?.holding.length > 0 ? false : true);
      setSeverity("success");
    } catch (error) {
      console.error(error);
      setSeverity("error");
      setMessageOpen("No transaction for this date");
      setSnackOpen(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={isMobile ? 2 : 4}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth disabled> 
            <InputLabel id="currency-label">Currency Code</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                value={currency || ""}
                label="Currency Code"
                onChange={(event) => setCurrency(event.target.value)}
                required
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="BRL">BRL</MenuItem> 
                <MenuItem value="MXN">MXN</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextField
            fullWidth
            id="report-date"
            label="Report Date"
            type="date"
            value={reportDate}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: minDate(),
              max: maxDate(),
            }}
            required
          />
        </Grid>
        <Grid
          item
          xs={isMobile ? 6 : undefined}
          textAlign={isMobile ? "center" : undefined}
        >
          <Button variant="contained" type="submit" size="large">
            GENERATE
          </Button>
        </Grid>
        <Grid
          item
          xs={isMobile ? 6 : undefined}
          textAlign={isMobile ? "center" : undefined}
        >
          <Button
            variant="contained"
            size="large"
            disabled={hide}
            onClick={handleDownload}
          >
            DOWNLOAD
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReportForm;
